import React from "react"
import { Link } from "gatsby"

import LayoutShort from "../components/layout_short"
import SessakuPanphlet from "../images/product/panphlet/sessaku.pdf"
import Seo from "../components/seo"

const thanksHtml = ({data,location}) => {
  return(
        
	<LayoutShort>
      <Seo
		pagetitle="資料ダウンロードありがとうございます"
		pagedesc="資料ダウンロードページ"
		pagepath={location.pathname}
	  />
    <div className="container-fluid thanks-error thanks">
        <div className="row">
          <div className="second-top about-mazin bg-img_cover black-filter">
            <div className="contents-title contents-title_white">DOWN<br className="d-md-none" />LOAD</div>
            <div className="contents-box">
              <h3 className="underline">ありがとうございます</h3><div />
              <div className="contents-comment">
                <div className="comment-br">
                  ご入力誠にありがとうございます。<br />
                  切削パッケージカタログはこちらのボタンからダウンロードできます。
                </div>
              </div>{/*.contents-comment*/}
            </div>{/*.contents-box*/}
          </div>{/*.second-top*/}
            <div className="dl-button">
              <a href={SessakuPanphlet} download="切削パッケージカタログ.pdf">
                <div className="link-top">ダウンロード</div>
              </a>
            </div>
            <Link to={`/`}>
                <div className="link-top link-top_gray">TOP</div>
            </Link>
        </div>{/*.row*/}
    </div>{/*.container-fluid*/}
    
	</LayoutShort>

  )
}

export default thanksHtml